<template>
    <div class='teacher-salary-modal-content'>
        <b-tabs content-class=''>
            <b-tab title='Начисления' active>
                <TeacherSalaryTableProfits :students='students' />
            </b-tab>

            <b-tab title='Доплата / Штрафы '>
                <TeacherSalaryTablePenalties :penalties='penalties' @on-delete="$emit('on-penalties-delete')" />
            </b-tab>

            <b-tab title='Выплаты'>
                <TeacherSalaryTablePayments :payments='payments' @on-delete="$emit('on-payments-delete')" />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import TeacherSalaryTableProfits from '@/components/admin/teacher-salary/table-tabs/TeacherSalaryTableProfits.vue';
import TeacherSalaryTablePayments from '@/components/admin/teacher-salary/table-tabs/TeacherSalaryTablePayments.vue';
import TeacherSalaryTablePenalties from '@/components/admin/teacher-salary/table-tabs/TeacherSalaryTablePenalties.vue';

export default {
    name: 'TeacherSalaryTable',
    components: { TeacherSalaryTablePenalties, TeacherSalaryTableProfits, TeacherSalaryTablePayments },
    props: {
        students: {
            type: Array,
            required: true,
        },
        penalties: {
            type: Array,
            required: true,
        },
        payments: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        dayjs,
    },
};
</script>

<style lang='scss' scoped>
.tab-content > .tab-pane {
    padding: 0 20px;
}
</style>