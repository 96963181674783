<template>
    <div class='teacher-salaries-total'>
        <b-list-group v-if='total' class='custom-list-group-horizontal ml-auto mr-4 flex-wrap' horizontal>
            <b-list-group-item>
                Оклад: <template v-if='typeof(total.lesson_total) != "undefined"'>{{ total.lesson_total }}</template>
            </b-list-group-item>
            <b-list-group-item>
                Донаты: <template v-if='typeof(total.donate) != "undefined"'>{{ total.donate }}</template>
            </b-list-group-item>
            <b-list-group-item>
                Доплаты: <template v-if='typeof(total.bonus) != "undefined"'>{{ total.bonus }}</template>
            </b-list-group-item>
            <b-list-group-item>
                Штрафы: <template v-if='typeof(total.penalty) != "undefined"'>{{ total.penalty }}</template>
            </b-list-group-item>
            <b-list-group-item>
                Заработано: <template v-if='typeof(total.total) != "undefined"'>{{ total.total }}</template>
            </b-list-group-item>
            <b-list-group-item>
                Выплачено: <template v-if='typeof(total.paid) != "undefined"'>{{ total.paid }}</template>
            </b-list-group-item>
            <b-list-group-item>
                Задолженность: <template v-if='typeof(total.debt) != "undefined"'>{{ total.debt }}</template>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>

<script>
import VCalendar from 'v-calendar/lib/components/date-picker.umd';
import TeacherSalaryList from '@/components/admin/teacher-salary/TeacherSalaryList';
import TeacherSalaryTable from '@/components/admin/teacher-salary/TeacherSalaryTable';

export default {
    name: 'TeacherSalariesTotal',
    components: { VCalendar, TeacherSalaryList, TeacherSalaryTable },
    props: {
        total: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style lang='scss' scoped>
    .teacher-salaries-total {
        .list-group-item {
            padding-left: 7px;
            padding-right: 7px;
        }
    }
</style>