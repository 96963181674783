import { render, staticRenderFns } from "./TeacherSalaryList.vue?vue&type=template&id=7bb5c906&scoped=true"
import script from "./TeacherSalaryList.vue?vue&type=script&lang=js"
export * from "./TeacherSalaryList.vue?vue&type=script&lang=js"
import style0 from "./TeacherSalaryList.vue?vue&type=style&index=0&id=7bb5c906&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bb5c906",
  null
  
)

export default component.exports