<template>
    <div class='teacher-salary-table-payment tsmp-students payments'>
        <div class='tsmp-students-header bg-secondary text-white font-weight-bold'>
            <div>Создано</div>
            <div>Сумма</div>
            <div class='text-center'>Создан</div>
            <div class='text-center'>Статус</div>
            <div class='text-center'>Действия</div>
        </div>

        <div class='tsmp-students-table-scrollbar tsmp-scrollbar'>
            <div
                v-for='(payment) in payments'
                :key='payment.id'
                class='tsmp-students-body-row border-bottom border-gray'
                :class="{ 'disabled': payment.canceled }"
            >
                <div class='tsmp-students-body-grid'>
                    <div>
                        <template
                            v-if="$store.getters['user/getRole'] === 'admin'"
                        >
                            <router-link :to="`/users/${payment.creator_id}`">
                                {{ payment.title }}
                            </router-link>
                        </template>
                        <template v-else>
                            {{ payment.title }}
                        </template>
                    </div>
                    <div class="text-success">
                        {{ parseInt(payment.amount) }} ({{ payment.currency }})
                    </div>
                    <div class='text-center'>{{ payment.date }}</div>
                    <div class='text-center fs-5'>
                        <i
                            class='fa'
                            :class="payment.canceled ? 'text-secondary opacity-50 fa-close' : 'text-success fa-check-circle'"
                        ></i>
                    </div>
                    <div class='text-center fs-5'>
                        <button
                            type='button'
                            title="Удалить"
                            class='btn btn-danger cursor-pointer'
                            :class="{ disabled: !canDelete(payment) }"
                            :disabled="!canDelete(payment)"
                            @click.prevent='handleDeleteClick(payment)'
                        >
                            <i class='fa fa-trash-o delete'></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DeleteModal from '@/components/Modal/DeleteModal.vue';

export default {
    name: 'TeacherSalaryTablePayments',
    props: {
        payments: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapGetters({
            getTeacherUserDetail: 'teacher/getTeacherUserDetail',
        }),
        canDelete() {
            return (payment) => this.$store.getters['user/getRole'] === 'admin' && !payment.canceled;
        },
    },
    methods: {
        handleDeleteClick(payment) {
            if (!this.canDelete(payment)) return false;

            this.$modal.show(
                DeleteModal,
                {
                    recordId: payment.id,
                    recordName: payment.title,
                    deleteHandler: async (id) => {
                        await this.deleteRecord(id);
                    }
                }, {name: 'DeleteModal'}
            );
        },
        async deleteRecord(id) {
            try {
                await this.$axios.post(`/crm/teachers/${this.$route.params.id}/teacher-salary/delete-pay/${id}`);
                await this.$emit('on-delete');
                this.$toasted.success('Выплата успешно удалена', {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            } catch (ex) {
                console.log('cant delete record: ', ex);
                let errMsg = 'Ошибка удаления выплаты';

                if (ex.response && ex.response.data.error) {
                    errMsg = ex.response.data.error;
                }

                this.$toasted.error(errMsg, {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            }
        },
    },
};
</script>

<style lang='scss' scoped>
.payments {
    .tsmp-students-header,
    .tsmp-students-body-grid {
        grid-template-columns: repeat(3, 1fr) 108px 132px;

        & > * {
            padding: 12px 20px;
        }
    }
}

.tsmp-students-header {
    display: grid;
    margin-left: -20px;
    margin-right: -20px;
    font-weight: 600;
    text-transform: uppercase;

    & > * {
        padding: 12px 0;

        &:first-child {
            padding-left: 20px;
        }
    }
}

.tsmp-students-body-row {
    position: relative;
    align-items: center;
    margin-left: -20px;
    margin-right: -20px;

    &:hover {
        cursor: pointer;
        transition: background-color .3s ease;
        background-color: rgba(#798892, 0.08);
    }

    &.active {
        background-color: rgba(#1B3C66, 0.1);

        .tsmp-students-body-row-icon {
            transform: rotateX(180deg);
        }
    }

    &.disabled {
        background-color: rgba(#1B3C66, 0.04);
        pointer-events: none;
    }
}

.tsmp-students-body-grid {
    display: grid;
    align-items: center;

    & > * {
        padding: 12px 0;

        &:first-child {
            padding-left: 20px;
        }
    }
}

.tsmp-students-body-row-icon {
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}

.tsmp-collapse {
    padding: 12px 0;
    cursor: default;

    .table {
        margin: 0;
    }

    tr {
        td, th {
            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 20px;
            }
        }
    }
}
</style>