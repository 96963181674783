<template>
    <div class='teacher-salary-table-penalties tsmp-students penalties'>
        <div class='tsmp-students-header bg-secondary text-white font-weight-bold'>
            <div>Создано</div>
            <div class='text-center'>Сумма</div>
            <div class='tsmp-comment'>Комментарий</div>
            <div class='text-center'>Подтвержден</div>
            <div class='text-center'>Оплачено</div>
            <div class='text-center'>Создан</div>
            <div class='text-center'>Действия</div>
        </div>

        <div class='tsmp-students-table-scrollbar tsmp-scrollbar'>
            <div
                v-for='(penalty) in penalties'
                :key='penalty.id'
                class='tsmp-students-body-row border-bottom border-gray'
            >
                <div class='tsmp-students-body-grid'>
                    <div>
                        <template
                            v-if="$store.getters['user/getRole'] === 'admin' && penalty.creator && penalty.creator.type === 'user'"
                        >
                            <router-link :to='`/users/${penalty.creator.id}`'>
                                {{ penalty.title }}
                            </router-link>
                        </template>
                        <template
                            v-else-if="$store.getters['user/getRole'] === 'admin' && penalty.creator && penalty.creator.type === 'transaction'"
                        >
                            <router-link :to='`/transactions-edit/${penalty.creator.id}`'>
                                {{ penalty.title }}
                            </router-link>
                        </template>
                        <template v-else-if='penalty.creator'>
                            {{ penalty.title }}
                        </template>
                    </div>
                    <td
                        class='text-center'
                        :class="{ 'text-success': penalty.operation === 1, 'text-danger': penalty.operation !== 1 }"
                    >
                        {{ penalty.operation === 1 ? '+' : '-' }}
                        {{ parseInt(penalty.amount) }} ({{ penalty.currency }})
                    </td>
                    <div class='tsmp-comment'>{{ penalty.comment }}</div>
                    <div class='text-center fs-5'>
                        <i
                            class='fa fa-check-circle'
                            :class="penalty.approved ? 'text-primary' : 'text-secondary opacity-50'"
                        ></i>
                    </div>
                    <div class='text-center fs-5'>
                        <i
                            class='fa fa-check-circle'
                            :class="penalty.paid ? 'text-success' : 'text-secondary opacity-50'"
                        ></i>
                    </div>
                    <div class='text-center'>{{ penalty.date }}</div>
                    <div class='text-center fs-5'>
                        <button
                            type='button'
                            :title="canDelete(penalty) ? 'Удалить' : 'Нельзя удалить штраф или доплату, так как выплата уже существует. Сначала удалите соответствующую выплату'"
                            class='btn btn-danger cursor-pointer'
                            :class="{ disabled: !canDelete(penalty) }"
                            :disabled="!canDelete(penalty)"
                            @click.prevent='handleDeleteClick(penalty)'
                        >
                            <i class='fa fa-trash-o delete'></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DeleteModal from '@/components/Modal/DeleteModal.vue';

export default {
    name: 'TeacherSalaryTablePenalties',
    props: {
        penalties: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        canDelete() {
            return (penalty) => this.$store.getters['user/getRole'] === 'admin' && !penalty.paid && penalty.amount === penalty.amount_debt;
        },
    },
    methods: {
        handleDeleteClick(penalty) {
            if (!this.canDelete(penalty)) return false;

            this.$modal.show(
                DeleteModal,
                {
                    recordId: penalty.id,
                    recordName: penalty.title,
                    deleteHandler: async (id) => {
                        await this.deleteRecord(id);
                    }
                }, {name: 'DeleteModal'}
            );
        },
        async deleteRecord(id) {
            try {
                await this.$axios.post(`/crm/teachers/${this.$route.params.id}/teacher-salary/delete-surcharge/${id}`);
                await this.$emit('on-delete');
                this.$toasted.success('Доплата/штраф успешно удалены', {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            } catch (ex) {
                console.log('cant delete record: ', ex);
                let errMsg = 'Ошибка удаления доплаты/штрафа';

                if (ex.response && ex.response.data.error) {
                    errMsg = ex.response.data.error;
                }

                this.$toasted.error(errMsg, {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            }
        },
    },
};
</script>

<style lang='scss' scoped>
.penalties {
    .tsmp-students-header,
    .tsmp-students-body-grid {
        grid-template-columns: repeat(6, 1fr) 132px;

        & > * {
            padding: 12px 20px;
        }
    }
}

.tsmp-students-header {
    display: grid;
    margin-left: -20px;
    margin-right: -20px;
    font-weight: 600;
    text-transform: uppercase;

    & > * {
        padding: 12px 0;

        &:first-child {
            padding-left: 20px;
        }
    }
}

.tsmp-students-body-row {
    position: relative;
    align-items: center;
    margin-left: -20px;
    margin-right: -20px;

    &:hover {
        cursor: pointer;
        transition: background-color .3s ease;
        background-color: rgba(#798892, 0.08);
    }

    &.active {
        background-color: rgba(#1B3C66, 0.1);

        .tsmp-students-body-row-icon {
            transform: rotateX(180deg);
        }
    }
}

.tsmp-students-body-grid {
    display: grid;
    align-items: center;

    & > * {
        &:first-child {
            padding-left: 20px;
        }
    }
}

.tsmp-students-body-row-icon {
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}

.tsmp-collapse {
    padding: 12px 0;
    cursor: default;

    .table {
        margin: 0;
    }

    tr {
        td, th {
            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 20px;
            }
        }
    }
}

.tsmp-comment {
    width: 220px;
}
</style>