<template>
    <div class='teacher-salary-table-profit tsmp-students'>
        <div class='tsmp-students-header bg-secondary text-white font-weight-bold'>
            <div>Ученик</div>
            <div class='text-center'>Занятий</div>
            <div>Сумма</div>
            <div class='text-center'>Задолженность</div>
            <div></div>
        </div>

        <div class='tsmp-students-table-scrollbar tsmp-scrollbar'>
            <div
                v-for='(student, idx) in students'
                :key='student.id'
                class='tsmp-students-body-row border-bottom border-gray'
                :class="{ 'active': isStudentRowVisible(idx) }"
                @mousedown='toggleStudentRowVisibility(idx)'
            >
                <div class='tsmp-students-body-grid'>
                    <div>{{ student.name }}</div>
                    <div class='text-center'>{{ student.lesson_count }}</div>
                    <div class='d-flex align-items-center'>
                        <div class='price'>{{ student.amount }}</div>

                        <span class='ml-2 fs-5'>
                           <i
                               class='fa fa-check-circle'
                               :class="student.approved ? 'text-primary' : 'text-secondary opacity-50'"
                               :title="student.approved ? 'Все платежи подтвержден' : 'Не все платежи подтверждены'"
                           ></i>
                        </span>
                    </div>
                    <div class='text-center'>{{ parseInt(student.debt) }}</div>
                    <div class='text-center tsmp-students-body-row-icon'>
                        <a @click.prevent='() => false'>
                            <i class='fa fa-angle-down'></i>
                        </a>
                    </div>
                </div>

                <div
                    v-show='isStudentRowVisible(idx)'
                    class='tsmp-collapse w-100 bg-white border-gray'
                    @click.stop='() => false'
                >
                    <table class='table table-hover mb-0'>
                        <thead class='table_head'>
                        <tr>
                            <th>№</th>
                            <th>Тип</th>
                            <th>Сумма</th>
                            <th class='text-center'>Подтвержден</th>
                            <th class='text-center'>Оплачено</th>
                            <th class='text-center'>Дата урока</th>
                            <th class='text-center'>Создан</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for='salary in student.salaries' :key='salary.id'>
                            <td>{{ salary.id }}</td>
                            <td @mousedown.stop>
                                <template
                                    v-if="$store.getters['user/getRole'] === 'admin' && salary.creator && salary.creator.type === 'user'"
                                >
                                    <router-link :to='`/users/${salary.creator.id}`'>
                                        {{ salary.title }}
                                    </router-link>
                                </template>
                                <template
                                    v-else-if="$store.getters['user/getRole'] === 'admin' && salary.creator && salary.creator.type === 'transaction'"
                                >
                                    <router-link :to='`/transactions-edit/${salary.creator.id}`'>
                                        {{ salary.title }}
                                    </router-link>
                                </template>
                                <template v-else-if='salary.creator'>
                                    {{ salary.title }}
                                </template>
                            </td>
                            <td
                                :class="{ 'text-success': salary.operation === 1, 'text-danger': salary.operation !== 1 }"
                            >
                                {{ salary.operation === 1 ? '+' : '-' }}
                                {{ parseInt(salary.amount) }} ({{ salary.currency }})
                            </td>
                            <td class='text-center fs-5'>
                                <i class='fa fa-check-circle'
                                   :class="salary.approved ? 'text-primary' : 'text-secondary opacity-50'"></i>
                            </td>
                            <td class='text-center fs-5'>
                                <i class='fa fa-check-circle'
                                   :class="salary.paid ? 'text-success' : 'text-secondary opacity-50'"></i>
                            </td>
                            <td class='text-center'>{{ salary.date }}</td>
                            <td class='text-center'>{{ salary.created_at }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
    name: 'TeacherSalaryTableProfit',
    props: {
        students: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            visibleStudentRows: [],
        };
    },
    computed: {
        isStudentRowVisible() {
            return (idx) => this.visibleStudentRows.includes(idx);
        },
    },
    methods: {
        dayjs,
        toggleStudentRowVisibility(rowIdx) {
            if (this.isStudentRowVisible(rowIdx)) {
                this.visibleStudentRows = this.visibleStudentRows.filter(row => row !== rowIdx);
            } else {
                this.visibleStudentRows.push(rowIdx);
            }
        },
    },
};
</script>

<style lang='scss' scoped>
.tsmp-students-header {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 52px;
    margin-left: -20px;
    margin-right: -20px;
    font-weight: 600;
    text-transform: uppercase;

    & > * {
        padding: 12px 0;

        &:first-child {
            padding-left: 20px;
        }
    }
}

.tsmp-students-body-row {
    position: relative;
    align-items: center;
    margin-left: -20px;
    margin-right: -20px;

    &:hover {
        cursor: pointer;
        transition: background-color .3s ease;
        background-color: rgba(#798892, 0.08);
    }

    &.active {
        background-color: rgba(#1B3C66, 0.1);

        .tsmp-students-body-row-icon {
            transform: rotateX(180deg);
        }
    }
}

.tsmp-students-body-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 52px;
    align-items: center;

    & > * {
        padding: 12px 0;

        &:first-child {
            padding-left: 20px;
        }
    }
}

.tsmp-students-body-row-icon {
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}

.tsmp-collapse {
    padding: 12px 0;
    cursor: default;

    .table {
        margin: 0;
    }

    tr {
        td, th {
            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 20px;
            }
        }
    }
}

.tsmp-comment {
    width: 220px;
}
</style>