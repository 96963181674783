<template>
    <table class='table table-hover'>
        <thead class='table_head'>
        <tr>
            <th>№</th>
            <th>Тип</th>
            <th>Сумма</th>
            <th class='tsmp-comment'>Комментарий</th>
            <th class='text-center'>Подтвержден</th>
            <th class='text-center'>Оплачено</th>
            <th class='text-center'>Создано</th>
            <th class='text-center'>Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for='salary in salaries' :key='salary.id'>
            <td>{{ salary.id }}</td>
            <td>
                <template
                    v-if="$store.getters['user/getRole'] === 'admin' && salary.creator && salary.creator.type === 'user'">
                    <router-link :to='`/users/${salary.creator.id}`'>{{ salary.title }}</router-link>
                </template>
                <template
                    v-else-if="$store.getters['user/getRole'] === 'admin' && salary.creator && salary.creator.type === 'transaction'">
                    <router-link :to='`/transactions-edit/${salary.creator.id}`'>{{ salary.title }}</router-link>
                </template>
                <template v-else-if='salary.creator'>{{ salary.title }}</template>
            </td>
            <td :class="{ 'text-success': salary.operation === 1, 'text-danger': salary.operation !== 1 }">
                {{ salary.operation === 1 ? '+' : '-' }} {{ salary.amount }} ({{ salary.currency }})
            </td>
            <td class='tsmp-comment'>{{ salary.comment }}</td>
            <td class='text-center fs-5'>
                <i class='fa fa-check-circle'
                   :class="salary.approved ? 'text-primary' : 'text-secondary opacity-50'"></i>
            </td>
            <td class='text-center fs-5'>
                <i class='fa fa-check-circle' :class="salary.paid ? 'text-success' : 'text-secondary opacity-50'"></i>
            </td>
            <td class='text-center'>{{ salary.date }}</td>
            <td class='text-center'>
                <button
                    type='button'
                    :title="canDelete(salary) ? 'Удалить' : 'Нельзя удалить штраф или доплату, так как выплата уже существует. Сначала удалите соответствующую выплату'"
                    class='btn btn-danger cursor-pointer'
                    :class="{ disabled: !canDelete(salary) }"
                    :disabled="!canDelete(salary)"
                    @click.prevent='handleDeleteClick(salary)'
                >
                    <i class='fa fa-trash-o delete'></i>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import dayjs from 'dayjs';
import DeleteModal from '@/components/Modal/DeleteModal.vue';

export default {
    name: 'TeacherSalaryList',
    props: {
        salaries: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        canDelete() {
            return (penalty) => this.$store.getters['user/getRole'] === 'admin' && !penalty.paid && penalty.amount === penalty.amount_debt;
        },
    },
    methods: {
        dayjs,
        handleDeleteClick(penalty) {
            if (!this.canDelete(penalty)) return false;

            this.$modal.show(
                DeleteModal,
                {
                    recordId: penalty.id,
                    recordName: penalty.title,
                    deleteHandler: async (id) => {
                        await this.deleteRecord(id);
                    }
                }, {name: 'DeleteModal'}
            );
        },
        async deleteRecord(id) {
            try {
                await this.$axios.post(`/crm/teachers/${this.$route.params.id}/teacher-salary/delete-surcharge/${id}`);
                await this.$emit('on-delete');
                this.$toasted.success('Доплата/штраф успешно удалены', {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            } catch (ex) {
                console.log('cant delete record: ', ex);
                let errMsg = 'Ошибка удаления доплаты/штрафа';

                if (ex.response && ex.response.data.error) {
                    errMsg = ex.response.data.error;
                }

                this.$toasted.error(errMsg, {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            }
        },
    },
};
</script>

<style scoped>
.tsmp-comment {
    width: 220px;
}
</style>